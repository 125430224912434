import $ from 'jquery'
import 'what-input'
import Swiper from 'swiper'
import { Navigation, Pagination, Controller, A11y, EffectFade } from "swiper/modules";
Swiper.use([Navigation, Pagination, Controller, A11y, EffectFade]);
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

window.jQuery = $
window.$ = $

import './lib/foundation-explicit-pieces'

gsap.registerPlugin(ScrollTrigger)

$(document).foundation()

const swipers = {
	init: function () {
		const swiperGallery = new Swiper('.swiper--gallery', {
			slidesPerView: 'auto',
			spaceBetween: 14,
			loop: false,
			breakpoints: {
				640: {
					spaceBetween: 28
				}
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'fraction',
				renderFraction: function (currentClass, totalClass) {
					return '<span class="' + currentClass + '"></span><span class="divider"></span><span class="' + totalClass + '"></span>'
				}
			},
			navigation: {
				nextEl: '.swiper-button--next',
				prevEl: '.swiper-button--prev'
			}
		})

		const swiperMonet = new Swiper('.swiper--monet', {
			slidesPerView: 1,
			spaceBetween: 0,
			loop: false,
			speed: 500,
			breakpoints: {
				640: {
					spaceBetween: 0,
					speed: 800,
				}
			}
		})

		const swiperText = new Swiper('.swiper--text', {
			slidesPerView: 1,
			spaceBetween: 14,
			loop: false,
			speed: 400,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			breakpoints: {
				640: {
					spaceBetween: 28,
					speed: 400,
				}
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'fraction',
				renderFraction: function (currentClass, totalClass) {
					return '<span class="' + currentClass + '"></span><span class="divider"></span><span class="' + totalClass + '"></span>'
				}
			},
			navigation: {
				nextEl: '.swiper-button--next',
				prevEl: '.swiper-button--prev'
			},
			controller: {
				control: swiperMonet
			}
		})

		swiperMonet.controller.control = swiperText;

		// swiperMonet.controller.control(swiperText);
		// window.swiperMonet = swiperMonet;
		// window.swiperText = swiperText;
	}
}

const animations = {
	init: function () {
		gsap.utils.toArray('.brush').forEach((item, index) => {
			let img = item.querySelector('img')

			let stsetting = {
				trigger: item,
				start: 'center bottom',
				end: 'bottom top',
				toggleActions: 'play none none reverse'
			}

			let clipPathFrom, clipPathTo
			if (item.classList.contains('brush--rtl')) {
				clipPathFrom = 'polygon(0% 0%, 100% 100%, 100% 100%, 0% 0%)'
				clipPathTo = 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
			} else if (item.classList.contains('brush--btt')) {
				clipPathFrom = 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)'
				clipPathTo = 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
			} else {
				clipPathFrom = 'polygon(0% 0%, 100% 0%, 100% 0, 0% 0%)'
				clipPathTo = 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
			}

			gsap.fromTo(
				img,
				{
					clipPath: clipPathFrom,
					opacity: 0
				},
				{
					clipPath: clipPathTo,
					opacity: 1,
					duration: 0.8,
					ease: 'power2.inOut',
					scrollTrigger: stsetting
				}
			)

			// baloon animation
			let balloon = $('#monet-balloon')

			var float = gsap.timeline({ repeat: -1, yoyo: true, repeatRefresh: true })

			float.to(balloon, {
				x: function () {
					return getRand(-20, 20)
				},
				y: function () {
					return getRand(-20, 10)
				},
				y: function () {
					return getRand(-20, 20)
				},
				rotation: function () {
					return getRand(-10, 10)
				},
				duration: 2,
				ease: 'linear.inOut'
			})

			function getRand(min, max) {
				return Math.random() * (max - min) + min
			}
		})
	}
}

const forms = {
	init: function () {
		const $contactForm = $(".form");
		$contactForm.on("submit", function (e) {
			e.preventDefault();

			const $form = $(this);
			const $formMessages = $form.find(".form__response");
			const $button = $form.find("button");

			$button.attr("disabled", true).addClass("is-loading");
			$formMessages.html("").hide();

			$.ajax({
				type: "post",
				url: $form.attr("action"),
				data: $form.serialize()
			})
				.done(function (data) {
					$button.attr("disabled", false).removeClass("is-loading");
					$formMessages.removeClass("alert").addClass("success");
					$form[0].reset();

					// Set the message text.
					if (data && data !== "") {
						$formMessages.html(data).show();
					}

					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						'event': 'formSubmissionSuccess',
						'formType': 'default',
					});
				})
				.fail(function (data) {
					$button.attr("disabled", false).removeClass("is-loading");
					$formMessages.removeClass("success").addClass("alert");

					// Set the message text.
					if (data.responseText && data.responseText !== "") {
						$formMessages.html(data.responseText).show();
					} else {
						$formMessages.html('Pri odosielaní sa vyskytol problém, skúste to znova.').show();
					}
				});

			return false;
		});
	}
}

swipers.init();
animations.init();
forms.init();

const hashLinks = document.querySelectorAll('a[href^="#"]');
hashLinks.forEach(a => {
	if (a.getAttribute("href") === "#") return;

	const targetId = a.getAttribute('href').substring(1);
	const targetElement = document.querySelector(a.getAttribute("href"));

	if (targetElement) {
		a.addEventListener("click", e => {
			let offset = window.innerWidth < 1024 ? -80 : -108;
			e.preventDefault();
			document.documentElement.scrollTo({
				top: targetElement.offsetTop + offset + 1,
				behavior: 'smooth'
			});
		});
	}
});